import React, { useEffect } from 'react';
import { motion } from "framer-motion";

import './about.scss';
import { aboutLoading } from '../../signlas/globle';
import bagher from '../../assets/images/Bagher.jpg';
import { RoughNotation } from 'react-rough-notation';

export default function About() {
    useEffect(() => {
        aboutLoading.value = false;
    }, []);

    return (
        <div
            className='aboutPage'
            id='aboutSection'
        >
            <motion.div
                className='info'
                id='aboutInfo'
            >
                <div className='line' />

                <div className='aboutWrapper'>
                    <div className='line' />
                    <h2 className='aboutTitle'>About</h2>
                    <div className='about'>
                        <div className='line' />
                        <p className='aboutText withLineAround'>
                            I'm a
                            <RoughNotation className="roughNotation" type="underline" show={true} padding={[2, 0]} >
                                &nbsp; fullstack web developer &nbsp;
                            </RoughNotation>
                            <span className='afterLineAround'>
                            with expertise in building scalable and high performance web and mobile applications. Skilled in modern development frameworks, blockchain integration, and cloud services.
                            </span>
                        </p>

                        <p className='aboutText withOutLineAround'>
                            I'm a fullstack web developer with over 3 years of experience in creating web applications. My expertise spans across the full range of development, from backend systems to engaging and responsive front-end interfaces.
                        </p>
                    </div>
                </div>

                <div className='skillsWrapper'>
                    <div className='line' />
                    <h2 className='skillsTitle'>Skills</h2>

                    <div className='skill Languages'>
                        <div className='line' />
                        <h3 className='skillHeading'>Languages</h3>
                        <ul className='skill-list'>
                            <div className='line' />
                            <li>JavaScript</li>
                            <li>TypeScript</li>
                            <li>Python</li>
                            <li>PHP</li>
                            <li>C#</li>
                            <li>HTML</li>
                            <li>CSS</li>
                        </ul>
                    </div>

                    <div className='skill frameworks'>
                        <div className='line' />
                        <h3 className='skillHeading'>Frameworks</h3>
                        <ul className='skill-list'>
                            <div className='line' />
                            <li>React JS</li>
                            <li>NEXT.JS</li>
                            <li>three.js</li>
                            <li>Node Js</li>
                            <li>Nest JS</li>
                            <li>Electron JS</li>
                            <li>Laravel</li>
                        </ul>
                    </div>

                    <div className='skill other'>
                        <div className='line' />
                        <h3 className='skillHeading'>Other</h3>
                        <ul className='skill-list'>
                            <div className='line' />
                            <li>Git</li>
                            <li>Prisma</li>
                            <li>SQL</li>
                            <li>Postgresql</li>
                            <li>MongoDB</li>
                            <li>CI/CD</li>
                            <li>Socket.io</li>
                            <li>REST API</li>
                            <li>Websockets</li>
                            <li>WebRTC</li>
                            <li>Webflow</li>
                            <li>Wordpress</li>
                            <li>Squarespace</li>
                            <li>UiPath</li>
                            <li>Azure</li>
                        </ul>
                    </div>
                </div>
            </motion.div>

            <div className='imgBagherCon'>
                <img className='imgBagher' src={bagher} alt="" />
            </div>
        </div>
    )
}
