import React from 'react';
import { useSignalEffect } from '@preact/signals-react';
import 'bear-react-carousel/dist/index.css';

import "./styles/app.scss";
import * as page from './pages';
import * as features from './features';
import { mainLoading, aboutLoading, projectsLoading, footerLoading } from "./signlas/globle";

function App() {
  useSignalEffect(() => {
    if (mainLoading.value === false && aboutLoading.value === false && projectsLoading.value === false && footerLoading.value === false) {
      document.getElementById('loading').style.opacity = 0;
      setTimeout(function () {
        document.getElementById('loading').style.visibility = 'hidden';
        document.documentElement.style.overflowY = 'visible';
      }, 500);

    }
  }, [mainLoading, aboutLoading, projectsLoading, footerLoading]);

  return (
    <div className='app'>
      <page.Loading />
      <page.Main />
      <page.Projects />
      <features.Footer />
    </div>
  );
}

export default App;
